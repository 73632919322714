.logoutButton {
  border: 1px solid blue;
  color: blue;
  border-width: 0px 0px 1px 0px;
  background-color: transparent;
}

.logoutButton:hover {
  color: darkblue;
  border-color: darkblue;
}
