.selectFullWidth {
  width: 100%;
  margin-bottom: 20px;
  color: black;
}

.WorkContext {
  padding: 30px;
}

.workContextInput {
  width: 75%;
  margin-bottom: 20px;
}

.workContextInputFull {
  width: 100%;
  margin-bottom: 20px;
}

.navigationButtons {
  padding-top: 20px;
  display: flex;
  padding-bottom: 20px;
}

.NavigationButton {
  width: 90%;
  margin-left: 5%;
  height: 60px;
  padding: 20px;
  background-color: #f5921e !important;
  border-color: black !important;
}

.DeleteCurrentEntryButton {
  width: 90%;
  margin-left: 5%;
  height: 60px;
  padding: 20px;
  background-color: #d9534f !important;
  color: white !important;
}

.nav-link:not(.active){
  color: #000000 !important;
}

.nav-link {
  &.active {
    color: #f5921e !important;
  }
}

.gpsButton {
  background-color: #f5921e !important;
  border-color: black !important;
}

.formArea {
  padding: 0 5% 0 5%;
}

.inputFieldWrapper {
  margin-bottom: 20px;
  margin-right: 20px;
}

.inputFieldWrapperFull {
  width: 100%;
  margin-bottom: 20px;
}

.formControl {
  width: 75%;
  margin: 0px;
  margin-bottom: 20px !important;
}

.tabWrapper {
  padding: 20px 20px 20px 20px;
  border: solid 1px #e0e0e0;
  border-width: 0px 1px 1px 1px;
  margin-bottom: 20px;
}

.gpsButton {
  margin-top: 20px;
  width: calc(50% - 20px);
  margin: 20px 20px 20px 0;
  height: 60px;
}

.LatLonButton {
  padding: 15px 24px !important;
  background-color: #f5921e !important;
  border-color: black !important;
}

.missingField {
  background-color: rgba(255, 0, 55, 0.344);
  color: red;
  padding: 30px;
  border: 1px solid black;
  border-radius: 10px;
  margin-bottom: 20px;
}
