.cocLabInput {
  width: 100%;
  font-size: large;
  height: 40px;
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  border-bottom: 3px solid grey;
}

.CocPage {
  margin: 20px;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}

.boreholePaginationNext {
  margin: auto;
}

.boreholePaginationPrev {
  margin: auto;
}

.coclabErrorMessage {
  background-color: red;
  color: black;
  padding: 20px;
  margin: 20px;
}