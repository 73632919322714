.SiteAdminForm {
  margin: 50px;
  border: 2px solid black;
  padding: 20px; 
}

.existingSiteArea {
  display: flex;
}

.existingSiteLabel {
  margin-right: 20px;
}

.ProjectSelect {
  width: 80%;
  height: 40px;
}

.siteActiveArea {
  display: flex;
  margin-right: 50px;
}

.siteActiveLabel {
  margin-right: 20px;
  padding-top: 10px;
}

.ManualArea {
  display: flex;
}

.siteManualLabel {
  margin-right: 20px;
  padding-top: 10px;
}

.siteAdminFormCheckboxArea {
  display: flex;
}

.provinceIdLabel {
  margin-right: 20px;
}

.nameLabel {
  margin-right: 20px;
}

.wellLicenseLabel {
  margin-right: 20px;
}

.uwiLabel {
  margin-right: 20px;
}

.commentsLabel {
  margin-right: 20px;
}

.latLonArea {
  padding-top: 10px;
}

.latLabel{
  margin-right: 20px;
}

.lonLabel {
  margin-right: 20px;
  margin-left: 20px;
}

.labelCol {
  width: 20%;
}

.inputCol {
  width: 80%;
}

.provinceIDInput {
  width: 100%;
}

.nameInput {
  width: 100%;
}

.wellLicenseInput {
  width: 100%;
}

.commentsInput {
  width: 100%;
}

.uwiInput {
  width: 100%;
}

.submitButtonSiteAdminForm {
  margin-top: 20px;
  width: 100px;
  font-size: large;
  height: 30px;
  border-radius: 5px;
  background-color: #f5921e;
  border: solid 1px black;
  font-weight: bold;
  color: white;
  margin-left: calc(50% - 50px);
}
