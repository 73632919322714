.PreviousSubmissions {
  margin-top: 20px;
  border-top: 2px solid #f5921e;
  padding: 20px 0px;
}

.paginationgroup {
  display: flex;
  list-style: none;
}

.paginationgroup li {
  margin-left: 10px;
}

.active {
  color: #f5921e;
}

.labCOCButtonSubmitted {
  margin-left: auto !important;
  background-color: #f5921e !important;
  color: white !important;
}