html {
  height: 100%;
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.leaflet-container {
  height: 600px;
  width: 100%;
  margin: 0 auto;
}

.panel-primary {
  background-color: #44b22f;
  border-color: #44b22f;
}

.panel-primary>.panel-heading {
  color: #fff;
  background-color: #44b22f;
  border-color: #44b22f;
}

/* Navigation */
.Navigation {
  text-align: left;
}

.Navigation-Hamburger {
  float: left;
  color: white !important;
}

.SidePanel {
  margin-left: 10px;
}

.SidePanel-Subtitle {
  font-size: 0.8em;
  color: #232323
}

.SidePanel-RightSide {
  float: right;
  margin-right: 10px;
}

/* Nested list */
.just-padding {
  padding: 15px;
}

.list-group.list-group-root {
  padding: 0;
  overflow: hidden;
}

.list-group.list-group-root .list-group {
  margin-bottom: 0;
}

.list-group.list-group-root .list-group-item {
  border-radius: 0;
  border-width: 1px 0 0 0;
}

.list-group.list-group-root>div>.list-group-item:first-child {
  border-top-width: 1px;
}

.list-group.list-group-root>div>.list-group>.list-group-item {
  padding-left: 30px;
}

.list-group.list-group-root>div>.list-group>div>.list-group>.list-group-item {
  padding-left: 45px;
}

.list-group-item .glyphicon {
  margin-right: 5px;
}

.nested-list ul {
  list-style-type: none;
  margin-left: 5px;
  padding-left: 10px;
}

/* Side Panel */
.SidePanel {
  margin-left: 10px;
  text-align: left;
}

.SidePanel-Subtitle {
  font-size: 0.8em;
  color: #232323
}

.SidePanel-RightSide {
  float: right;
  margin-right: 10px;
}

/* Welcome page */
.WelcomePage-Button {
  margin:10px;
  width:80%;
}

.buttonDiv {
  margin-top: 5px;
}

.nested-grid {
  padding: 0px !important;
  width: 100% !important;
}
