.siteNameInput {
  margin-right: 10px !important;
  margin-bottom: 10px !important;
}

.addSiteButton {
  width: 200px;
  height: 60px;
  background-color: #f5921e !important;
  border-color: black !important;
}
