.AdminPage {
  margin: 50px;
}

.AdminPageTitle {
  font-size: xx-large;
}

.TabArea {
  height: 50px;
  width: 100px;
}

.TabArea {
  display: flex;
  flex-direction: row;
}

.TabButton {
  border: solid 2px lightgrey;
  border-bottom: none;
  border-radius: 5px 5px 0px 0px;
  margin-right: 10px;
  padding: 10px;
}

.selected {
  border: solid 2px #f5921e;
  border-bottom: none;
}

.TabContent {
  border: solid 2px lightgrey;
  padding: 10px;
}