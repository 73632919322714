.SubmissionPageContent {
  margin: 50px;
}

.FieldFormButton {
  width: 300px;
  height: 50px;
  padding-top: 13px !important;
  margin-bottom: 20px;
  background-color: #f5921e !important;
  border-color: black !important;
}
