.SubmissionItemButton {
  width: 125px;
  margin-left: auto;
  background-color: #f5921e !important;
  border-color: black !important;
  margin: 10px;

}

.SubmissionItem {
  margin-bottom: 10px;
  box-shadow: 0px 0px 5px 0px #000000;
}

.AccordianSummary {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.SubmittedSubmissionDescription {
  max-width: 300px;
}

.submissionItemButtonArea {
  width: 140px;
}
