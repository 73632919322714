.userList {
  width: 100%;
  margin-top: 50px;
}

.LabelCol {
  width: 35%;
}

.InputCol {
  width: 18%;
}