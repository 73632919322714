.UnsubmittedButtons {
  background-color: #f5921e !important;
  border-color: black !important;
}

.UnsubmittedButtonDelete {
  background-color: #d9534f !important;
  color: white !important;
}

.unsubmittedPanelContent {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.unsubmittedItems {
  .MuiAccordionSummary-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}