.submissionFilterControls {
  width: 100%;
  --primary-theme: #f5921e;
  --danger: #d9534f;
  --text-area: #ECE8E8;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.submissionSortByArea {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  width: 50%;
  padding-left: 15px;;
  vertical-align: bottom;
  position: relative;
}

.sortBySelect {
  width: calc(100% - 50px);
  height: 30px;
  font-size: 16px;
  border-radius: 5px 0px 0px 5px;
  border: solid 2px var(--text-area);
  position: absolute;
  bottom: 0px;
}

span.glyphicon.direction {
  position: absolute;
  top: 40px;
  right: 6px;
  font-size: 20px;
  height: 30px;
  border-radius: 0px 5px 5px 0px;
  border: solid 1px black;
  background-color: var(--primary-theme);
  padding: 5px;
  color: white;
  cursor: pointer;
}

.submissionSearchArea {
  width: 50%;
  padding-top: 20px;
  border-left: solid 2px var(--primary-theme);
  padding-left: 10px;
  padding-right: 15px;
}

.searchInputArea {
  display: flex;
  flex-direction: row;
}

.searchInput {
  width: 100%;
  height: 30px;
  font-size: 16px;
  border-radius: 5px 0px 0px 5px;
  border: solid 2px var(--text-area);
  border-right: none;
}

.searchInput:focus {
  outline: none;
}

.searchButton{
  height: 30px;
  border-radius: 0px 5px 5px 0px;
  background-color: var(--primary-theme);
  border: solid 1px black;
  font-weight: bold;
  color: white;
}