.addAtComment {
  background-color: #f5921e;
  border-radius: 5px;
  color: white;
  width: 100%;
  height: 60px;
}

.AtCommentButton {
  background-color: #f5921e !important;
  border-color: black !important;
}

.atCommentCancelButton {
  background-color: #d9534f !important;
  color: white !important;
}

.addAtCommentArea {
  display: flex;
  flex-direction: column;
}

.atCommentInput {
  margin: 20px 10px !important;
}
