.intervalForm {
  padding: 10px;
  border-radius: 5px;
  border: solid 1px lightgrey;
  margin: 0px 10px 10px;
  width: calc(100% - 125px) !important;
}

.intervalHeader {
  width: 90%;
  font-size: large;
}

hr {
  width: 90%;
}

.submitArea {
  justify-content: space-between;
  display: flex;
}

.intervalFormCancelButton {
  background-color: #d9534f;
  border-radius: 5px;
  color: white;
  width: 100%;
  min-height: 40px;
  margin: 10px;
}

.intervalFormSaveButton {
  background-color: #f5921e;
  border-radius: 5px;
  color: white;
  width: 100%;
  min-height: 40px;
  margin: 10px;
}

.intervalFormSaveButton[disabled]{
  opacity: .5;
}

.intervalFormCancelButton[disabled] {
  opacity: .5;
}

.intervalAlertArea {
  background-color: red !important;
  color: white !important;
}
