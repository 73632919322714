.saveFormButton {
  min-width: 300px;
  height: 50px;
  background-color: #f5921e !important;
  border-color: black !important;
}

.backToFormButton {
  min-width: 300px;
  height: 50px;
  background-color: #f5921e !important;
  border-color: black !important;
  padding-top: 12px !important;
}

.ReviewPageContent {
  padding: 20px;
}
