.addNewIntervalButton {
  background-color: #f5921e;
  border-radius: 5px;
  color: white;
  width: 100%;
  height: 60px;
  margin: 15px 0px;
}

.addNewIntervalButton:disabled {
  opacity: .5;
}

.deleteAllIntervalsButton {
  margin: 10px;
  background-color: #f5921e !important;
  border: 1px solid black !important;
}

.deleteAllAtCommentsButton {
  margin: 10px;
  background-color: #f5921e !important;
  border: 1px solid black !important;
}

.intervalListButton {
  margin: 5px;
  background-color: #f5921e !important;
  border: 1px solid black !important;
}

.intervalControls {
  margin-bottom: 10px;
  @media only screen and (min-width: 766px) {
    max-width: 350px;
  }
}
