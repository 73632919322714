.addMoreSamplesButton {
  background-color: #f5921e !important;
  border-color: black !important;
}

.scrollToBottomButton {
  background-color: #f5921e !important;
  border-color: black !important;
}

.deleteAllSamplesButton {
  background-color: #d9534f !important;
  color: white !important;
}

.fieldSampleItem {
  display: flex;
  flex-direction: row;
}

.depthRangeArea {
  flex-direction: column;
  display: flex;
  margin-right: 20px;
  width: 300px;
}

.formControlSampleItem {
  display: flex !important;
  flex-direction: row !important;
  margin-top: 20px !important;
}

.fieldMeasurementsArea {
  margin-top: 24px;
  flex-direction: column;
  display: flex;
  margin-right: 20px;
}

.sampleJarsArea {
  margin-top: 24px;
  flex-direction: column;
  display: flex;
  margin-right: 20px;
}

.sampleJarsArea2 {
  margin-top: 24px;
  flex-direction: column;
  display: flex;
  margin-right: 20px;
}

.labLabel {
  margin-top: 8px;
}
